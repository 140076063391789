import styled from "styled-components";
import { Content } from "../components/elements";
import { TikTok } from "react-tiktok";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import "./video.css";

interface TikTokVideosProps {
  videoId: string;
  className: string;
}

const IframeContainer = styled.div`
  // left: 0;
  // width: 100%;
  // height: 500;
  // position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  transform: translate(-50%, -50%);
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  background-color: 011627;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0rem;
  text-transform: uppercase;
  background-color: #fff;
  border-radius: 16px;
  width: 100%;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;

  width: 500px;
  padding: 2rem;

  @media (max-width: 650px) {
    width: auto;
    padding: 0;
  }

  @media (max-width: 500px) {
    width: auto;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

export default function Videos() {
  const urlList = [
    "7214637764782968070",
    "7202784628699139334",
    "7198655738762251526",
    "7199456430020201734",
    "7196371463841025286",
    "7196007621277584646",
    "7193758115219787013",
    "7190022431157816582",
  ];
  const [currentVideo, setCurrentVideo] = useState<number>(0);

  const [videoId, setVideoId] = useState<string>(urlList[0]);

  function handleKeyPress(e: any) {
    if (e.key === "ArrowLeft") {
      handleVideoChange("left");
    } else if (e.key === "ArrowRight") {
      handleVideoChange("right");
    }
  }
  window.onkeydown = function (e: any) {
    handleKeyPress(e);
  };

  const handleVideoChange = (direction: string) => {
    if (direction === "left") {
      if (currentVideo === 0) {
        let lastVideoNr = urlList.length - 1;
        setCurrentVideo(lastVideoNr);
        setVideoId(urlList[lastVideoNr]);
      } else {
        setCurrentVideo(currentVideo - 1);
        setVideoId(urlList[currentVideo - 1]);
      }
    } else if (direction === "right") {
      if (currentVideo === urlList.length - 1) {
        setCurrentVideo(0);
        setVideoId(urlList[0]);
      } else {
        setCurrentVideo(currentVideo + 1);
        setVideoId(urlList[currentVideo + 1]);
      }
    }
  };

  return (
    <Content>
      <h1>Videos</h1>
      <NavigationContainer>
        <FontAwesomeIcon
          style={{
            height: "3rem",
            cursor: "pointer",
          }}
          icon={faAngleLeft}
          onClick={() => {
            handleVideoChange("left");
          }}
        />
        <Container>
          <TikTokVideos className="tik-tok-video" videoId={videoId} />
        </Container>
        <FontAwesomeIcon
          style={{
            height: "3rem",
            cursor: "pointer",
          }}
          icon={faAngleRight}
          onClick={() => {
            handleVideoChange("right");
          }}
        />
      </NavigationContainer>
    </Content>
  );
}

function TikTokVideos(props: TikTokVideosProps) {
  const src = `https://www.tiktok.com/embed/${props.videoId}`;
  return (
    <div className={"tik-tok-video"}>
      <Iframe src={src} scrolling="no" allowFullScreen={true} allow="encrypted-media"></Iframe>
    </div>
  );
}
