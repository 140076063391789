import styled, {keyframes} from "styled-components";
import {ContentLoad} from "../components/animations";
import {Content} from "../components/elements";
import showdown from "../assets/MinigameShowdownCropped.png";
import discordLogo from '../assets/discord-logo-white.png';
import catAndCapy from "../assets/catandcapy.png";

const IntroSpan = styled.span`
  font-size: 1.2em;

  // margin-left: 1rem;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Filler = styled.div`
  min-height: 100vh;
`;

const ContentHome = styled(Content)`
    // animation: ${ContentLoad} 700ms ease-out;
`;
const GameContainer = styled.div`
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  grid-gap: 2rem;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 1rem;
    border-radius: 0.5rem;
    place-items: normal start;
  }
`;
const GameTextContainer = styled.div`
  grid-row: 2;
  //display: flex;
  flex-direction: column;
  gap: 0.75rem;

  justify-content: center;
  display: block;
  height: 100%;
  
  @media (max-width: 768px) {
    grid-row: 3;
  }
`;

const SteamGame = styled.div`
  display: grid;
  grid-column: 1/-1;
  grid-row-start: -0;

  @media (max-width: 768px) {
    //Just a hacky thing for embedded stuff
    scale: 60%; 
    max-width: 0;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  width: 100%;
  max-width: 725px;
  margin-top: 6rem;

  @media (max-width: 768px) {
    max-width: 450px;
    margin-top: 2rem;
    ${GameContainer}:nth-child(2) ${GameTextContainer} {
      order: 1;
    }
  }
  @media (max-width: 480px) {
    max-width: 350px;
  }
`;

const GameImg = styled.img`
  grid-row: 2;
  width: 100%;
  height: 17rem;
  object-fit: contain;

  @media (max-width: 768px) {
    object-fit: cover;
    grid-row: 2;
  }
`;
const GameImgForMSRipBadJustForMargin = styled.img`
  grid-row: 2;
  width: 100%;
  height: 17rem;
  object-fit: contain;

  margin-bottom: -50px;
  
  @media (min-width: 768px) {
    object-fit: cover;
    grid-row: 2;
    margin-top: -80px;
  }
`;
const GameText = styled.h2`
  grid-row: 1;
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  text-align: left;
  width:  100%;
`;

const LinkButtons = styled.div`
  display: grid;
  grid-template-columns: 180px 20px 180px;
  grid-template-rows: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: end;
  
  @media (max-width: 768px) {
    margin: auto;
  }
`;

const GamePageButton = styled.a`
  grid-column: 1;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  border: 1px solid white;
  border-radius: 8px;
  margin-right: auto;
  padding: 10px 0px;
  background-color: black;
  color: white;  
  width: 100%;
`;

const DiscordButton = styled.a`
  grid-column: 3;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: end;
  border: 1px solid white;
  border-radius: 8px;
  background-color: black;
  color: white;
  height: 100%;
  width: 100%;
  
  img{
    //background-color: rebeccapurple;
    padding: 8px 10px;
    height: 95%;
  }
`;

const MobileButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 70px;

`;

const showdownText = `Minigame Showdown is a multiplayer game where you compete in different minigames with your friends.`;

export default function Home() {
    return (
        <ContentHome>
            <Content>
                <h1>Matato games</h1>
                <IntroSpan>
                    I am a game developer based in Norway. I am currently working on two games. One is a 3D platformer
                    called <b>Cat and Capy</b> and the other is a mobile multiplayer game called <b>Minigame Showdown</b>.
                </IntroSpan>
                <Container>
                    <GameContainer>
                        <GameText>Cat and Capy</GameText>
                        <GameTextContainer>
                            <p>
                            A 3D platformer where you play as a cat and a capybara trying to rescue your friends, captured by vicious birds.
                            </p>
                            <p>
                            The game is currently in Alpha and will be updated continuously. Currently, you can download the alpha on itch.io using the download button below.
                            The game is planned to be released for mobile and Steam.
                            </p>
                            <LinkButtons>
                                <GamePageButton href="https://matatogames.itch.io/cat-and-capy">Download
                                    Page</GamePageButton>
                                <DiscordButton href='https://discord.gg/MNFGUEfP6g' target="_blank">
                                    <img src={discordLogo}/>
                                </DiscordButton>
                            </LinkButtons>
                            {/*<div style={{ overflow: "hidden" }}>*/}
                            {/*  <div style={{ position: "relative", left: "-38%" }}>*/}
                            {/*    <iframe frameBorder="0"*/}
                            {/*            src="https://itch.io/embed/2055139?linkback=true" width="552" height="167"><a*/}
                            {/*      href="https://matatogames.itch.io/cat-and-capy">Cat and Capy - Alpha by Matato</a></iframe>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                        </GameTextContainer>
                        <GameImg
                            src={catAndCapy}
                            alt="catAndCapy"
                            style={{
                                objectFit: "cover",
                            }}
                        />
                        <SteamGame>
                            <iframe src="https://store.steampowered.com/widget/3108140/" frameBorder="0" width="646"
                                    height="190"></iframe>
                        </SteamGame>
                    </GameContainer>

                    <GameContainer>
                        <GameText>Minigame Showdown</GameText>
                        <GameTextContainer>
                            <p>{showdownText}</p>
                            <MobileButtonsContainer>
                                <a
                                    href="https://apps.apple.com/us/app/minigame-showdown/id6450846407?itsct=apps_box_badge&amp;itscg=30200"
                                    style={{
                                        gridColumn: 1,
                                    }}>
                                    <img
                                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1689724800"
                                        alt="Download on the App Store"
                                        style={{
                                            height: "100%",
                                            padding: "11px",
                                        }}
                                    />
                                </a>
                                <div
                                    style={{
                                        // display: "inline-block",
                                        overflow: "hidden",
                                        borderRadius: "13px",
                                        // width: "250px",
                                        height: "100%",
                                        gridColumn: 2,
                                    }}>
                                    <a
                                        style={{
                                            display: 'inherit',
                                            width: '100%',
                                            height: '100%'
                                        }}
                                        href='https://play.google.com/store/apps/details?id=com.Matato.MinigameShowdown&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                        <img
                                            style={{display: 'inherit', height: '100%'}}
                                            alt='Get it on Google Play'
                                            src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                                    </a>

                                </div>
                                {/*<a*/}
                                {/*    // href="https://apps.apple.com/us/app/minigame-showdown/id6450846407?itsct=apps_box_badge&amp;itscg=30200"*/}
                                {/*    style={{*/}
                                {/*        display: "inline-block",*/}
                                {/*        overflow: "hidden",*/}
                                {/*        borderRadius: "13px",*/}
                                {/*        width: "250px",*/}
                                {/*        margin: "0 0 -30px -60px",*/}
                                {/*        height: "83px",*/}
                                {/*        scale: "0.7",*/}
                                {/*        backgroundColor: "white",*/}
                                {/*        border: "2px white",*/}
                                {/*        gridColumn: 2,*/}
                                {/*    }}>*/}
                                {/*    <div style={{*/}
                                {/*        color: "black",*/}
                                {/*        display: "flex",*/}
                                {/*        justifyContent: "center",*/}
                                {/*        alignItems: "center",*/}
                                {/*        height: "100%",*/}
                                {/*        fontWeight: 600,*/}
                                {/*    }}>Coming to the Play Store soon!*/}
                                {/*    </div>*/}
                                {/*    /!*<img*!/*/}
                                {/*    /!*  src=""*!/*/}
                                {/*    /!*  alt="Download on the Play Store" style={{ borderRadius: "13px", width: "250px", height: "83px" }}/>*!/*/}
                                {/*</a>*/}
                            </MobileButtonsContainer>
                        </GameTextContainer>
                        <GameImgForMSRipBadJustForMargin
                            src={showdown}
                            alt="showdown"
                            style={{
                                objectFit: "contain",
                            }}
                        />
                    </GameContainer>
                </Container>
            </Content>
        </ContentHome>
    );
}
