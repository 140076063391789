import styled from "styled-components";

interface BorderDivProps {
  area?: string;
}

export const BorderDiv = styled.div<BorderDivProps>`
  grid-area: ${(props) => props.area || "border"};
  height: 2px;
  width: 90%;
  /*background-color: red;*/
  background: rgb(1, 22, 39);
  background: radial-gradient(circle, rgba(1, 22, 39, 1) 0%, rgba(220, 0, 0, 1) 0%, rgba(1, 22, 39, 1) 75%);
  margin: auto;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  z-index: 1;
  min-height: 100vh;
  margin: 0 auto;

  max-width: 725px;

  @media (max-width: 768px) {
    max-width: 450px;
  }
  @media (max-width: 480px) {
    max-width: 350px;
  }
`;
