import styled from "styled-components";
import {Content, BorderDiv} from "../components/elements";
// import merchHatOld from "../assets/merch/classic-dad-hat.jpg";
// import matatoHatOld from "../assets/merch/matato-hat.jpg";
import merchHat from "../assets/merch/trucker-hat-catcapy.jpg";
import matatoHat from "../assets/merch/trucker-hat-matato.jpg";
import pixelMug from "../assets/merch/mug.jpg";
import womanPink from "../assets/merch/womens-relaxed-pink.jpg";
import womanBlue from "../assets/merch/womens-relaxed-t-shirt-heather-blue-.jpg";
import mensAshe from "../assets/merch/mens-classic-tee-ash.jpg";
import printedToonMen from "../assets/merch/menstooncatcapy.png";
import printedToonWomen from "../assets/merch/womantooncatcapy.png";
import bucketCatAndCapy from "../assets/merch/bucket-hat-catandcapy.jpg";
import bucketMatato from "../assets/merch/bucket-hat-matato.jpg";
// import mensMaroon from "../assets/merch/mens-classic-tee-maroon.jpg";
import mensMaroon from "../assets/merch/mens-classic-tee-natural.webp";
import unisexHoodie from "../assets/merch/unisex-premium-hoodie.jpg";
import poster from "../assets/merch/enhanced-matte-paper-poster.jpg";
import shorts from "../assets/merch/unisex-athletic-long-shorts.jpg";
import mug from "../assets/merch/white-glossy-mug.jpg";
import headband from "../assets/merch/headband.jpg";
import laptopSleeve from "../assets/merch/laptop-sleeve-15.jpg";


const MerchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  padding: 0.25rem;
  cursor: pointer;
`;

const MerchGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px;
  grid-gap: 10px;
  width: 100%;
  max-width: 900px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    max-width: 450px;
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    max-width: 350px;
  }
`;

const MerchImage = styled.img`
  width: 100%;
`;

const MerchText = styled.p`
  font-size: 0.7rem;
  font-weight: 600;
  margin: 0;
  min-height: 27px;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 0.75rem;
`;

const MerchPrice = styled.p`
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  text-align: start;
`;

const MerchButton = styled.button`
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  text-align: center;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  padding: 0.5rem;
  cursor: pointer;
  transition: border-color 0.5s, background-color 0.5s, color 0.5s;

  :hover {
    background-color: #fff;
    border-color: transparent;
    color: #444;
  }
`;

const navigateToMerch = (url: string) => {
    window.open(url, "_blank");
};

export const Merch = () => {
    return (
        <Content>
            <h1>Merch</h1>
            <MerchGrid>
                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/en-no/products/toon-cat-and-capy-mens-classic-tee");
                }}>
                    <MerchImage src={printedToonMen}/>
                    <TextContainer>
                        <MerchText>{"Toon Cat & Capy Men's classic tee"}</MerchText>
                        <MerchPrice>From $16.00 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>
                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/en-no/products/toon-cat-and-capy-womens-relaxed-t-shirt");
                }}>
                    <MerchImage src={printedToonWomen}/>
                    <TextContainer>
                        <MerchText>{"Toon Cat & Capy Women's Relaxed T-Shirt"}</MerchText>
                        <MerchPrice>From $21.00 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>

                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/products/trucker-cap-pixel-cat-capy-embroidered");
                }}>
                    <MerchImage src={merchHat}/>
                    <TextContainer>
                        <MerchText>{"Hat (Pixel Cat & Capy Embroidered)"}</MerchText>
                        <MerchPrice>From $22.00 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>

                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/products/womens-relaxed-t-shirt-pixel-cat-capy-embroidered-1");
                }}>
                    <MerchImage src={womanPink}/>
                    <TextContainer>
                        <MerchText>{"Women's Relaxed T-Shirt (Pixel Cat & Capy Embroidered)"}</MerchText>
                        <MerchPrice>From $24.00 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>

                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/products/mens-classic-tee-pixel-cat-capy-embroidered");
                }}>
                    <MerchImage src={mensMaroon}/>
                    <TextContainer>
                        <MerchText>{"Men's Classic Tee (Pixel Cat & Capy Embroidered)"}</MerchText>
                        <MerchPrice>From $18.50 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>

                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/en-no/products/cat-capy-poster");
                }}>
                    <MerchImage src={poster}/>
                    <TextContainer>
                        <MerchText>{"Cat & Capy Poster"}</MerchText>
                        <MerchPrice>From $17.50 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>

                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/en-no/products/cat-capy-athletic-long-shorts-unisex");
                }}>
                    <MerchImage src={shorts}/>
                    <TextContainer>
                        <MerchText>{"Cat & Capy Athletic Long Shorts (Unisex)"}</MerchText>
                        <MerchPrice>From $39.00 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>

                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/en-no/products/cat-capy-laptop-sleeve");
                }}>
                    <MerchImage src={laptopSleeve}/>
                    <TextContainer>
                        <MerchText>{"Cat & Capy Laptop Sleeve"}</MerchText>
                        <MerchPrice>From $27.00 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>

                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/en-no/products/cat-capy-white-glossy-mug?variant=49636105519403");
                }}>
                    <MerchImage src={mug}/>
                    <TextContainer>
                        <MerchText>{"Cat & Capy White Glossy Mug"}</MerchText>
                        <MerchPrice>From $11.00 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>

                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/en-no/products/cat-capy-headband");
                }}>
                    <MerchImage src={headband}/>
                    <TextContainer>
                        <MerchText>{"Cat & Capy Headband"}</MerchText>
                        <MerchPrice>From $10.00 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>

                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/products/white-glossy-mug-pixel-cat-capy-1");
                }}>
                    <MerchImage src={pixelMug}/>
                    <TextContainer>
                        <MerchText>{"White Glossy Mug (Pixel Cat & Capy)"}</MerchText>
                        <MerchPrice>From $11.00 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>

                {/*OLD HAT TYPE*/}
                {/*<MerchContainer onClick={(e) => {navigateToMerch("https://matatogames.myshopify.com/products/matato-hat")}}>*/}
                {/*  <MerchImage src={matatoHat} />*/}
                {/*  <TextContainer>*/}
                {/*    <MerchText>{"Matato Hat"}</MerchText>*/}
                {/*    <MerchPrice>From $22.00 USD</MerchPrice>*/}
                {/*  </TextContainer>*/}
                {/*  <MerchButton>View in store</MerchButton>*/}
                {/*</MerchContainer>*/}

                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/en-no/products/matato-trucker-cap-3");
                }}>
                    <MerchImage src={matatoHat}/>
                    <TextContainer>
                        <MerchText>{"Matato Hat"}</MerchText>
                        <MerchPrice>From $22.00 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>

                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/products/womens-relaxed-t-shirt-pixel-cat-capy-printed-1");
                }}>
                    <MerchImage src={womanBlue}/>
                    <TextContainer>
                        <MerchText>{"Women's Relaxed T-Shirt (Pixel Cat & Capy Printed)"}</MerchText>
                        <MerchPrice>From $21.00 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>

                {/*OLD HAT TYPE*/}
                {/*<MerchContainer onClick={(e) => {navigateToMerch("https://matatogames.myshopify.com/products/hat-pixel-cat-capy-embroidered-1")}}>*/}
                {/*  <MerchImage src={merchHat} />*/}
                {/*  <TextContainer>*/}
                {/*    <MerchText>{"Hat (Pixel Cat & Capy Embroidered)"}</MerchText>*/}
                {/*    <MerchPrice>From $22.00 USD</MerchPrice>*/}
                {/*  </TextContainer>*/}
                {/*  <MerchButton>View in store</MerchButton>*/}
                {/*</MerchContainer>*/}

                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/products/mens-classic-tee-pixel-cat-capy-printed");
                }}>
                    <MerchImage src={mensAshe}/>
                    <TextContainer>
                        <MerchText>{"Men's Classic Tee (Pixel Cat & Capy Printed)"}</MerchText>
                        <MerchPrice>From $16.00 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>

                <MerchContainer onClick={(e) => {
                    navigateToMerch("https://matatogames.myshopify.com/products/unisex-hoodie-pixel-cat-capy-embroidered");
                }}>
                    <MerchImage src={unisexHoodie}/>
                    <TextContainer>
                        <MerchText>{"Unisex Hoodie (Pixel Cat & Capy Embroidered)"}</MerchText>
                        <MerchPrice>From $36.50 USD</MerchPrice>
                    </TextContainer>
                    <MerchButton>View in store</MerchButton>
                </MerchContainer>
                {/*<MerchContainer onClick={(e) => {*/}
                {/*    navigateToMerch("https://matatogames.myshopify.com/en-no/products/organic-bucket-hat-pixel-cat-capy-embroidered");*/}
                {/*}}>*/}
                {/*    <MerchImage src={bucketCatAndCapy}/>*/}
                {/*    <TextContainer>*/}
                {/*        <MerchText>{"Bucket Hat (Pixel Cat & Capy Embroidered)"}</MerchText>*/}
                {/*        <MerchPrice>From $26.00 USD</MerchPrice>*/}
                {/*    </TextContainer>*/}
                {/*    <MerchButton>View in store</MerchButton>*/}
                {/*</MerchContainer>*/}
                {/*<MerchContainer onClick={(e) => {*/}
                {/*    navigateToMerch("https://matatogames.myshopify.com/en-no/products/matato-bucket-hat-2");*/}
                {/*}}>*/}
                {/*    <MerchImage src={bucketMatato}/>*/}
                {/*    <TextContainer>*/}
                {/*        <MerchText>{"Matato Bucket Hat"}</MerchText>*/}
                {/*        <MerchPrice>From $26.00 USD</MerchPrice>*/}
                {/*    </TextContainer>*/}
                {/*    <MerchButton>View in store</MerchButton>*/}
                {/*</MerchContainer>*/}
            </MerchGrid>
        </Content>
    );
};
