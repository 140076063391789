import styled from "styled-components";

const DonationSegmentComponent = styled.footer`
  grid-area: footer;
  //display: flex;
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;

  font-weight: 700;
  padding: 1rem;
  margin-top: 60px;
`;

export const DonationSegment = () => {
    return (
        <DonationSegmentComponent>
            <span style={{margin: '20px', color: "#9199c4"}}>Want to help me turn this hobby into a job? 🤗</span>
            <form action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="hosted_button_id" value="62LUH7ZZXXSJN"/>
                <input type="image" src="https://www.paypalobjects.com/en_US/NO/i/btn/btn_donateCC_LG.gif"
                       style={{border: "0"}} name="submit" title="PayPal - The safer, easier way to pay online!"
                       alt="Donate with PayPal button"/>
                <img alt="" style={{border: "0"}} src="https://www.paypal.com/en_NO/i/scr/pixel.gif" width="1"
                     height="1"/>
            </form>
            <span style={{margin: '20px', lineHeight:'1.5em', color: "#9199c4"}}>
                Donating 10USD+ will get you into the Cat and Capy Alpha supporter credits!<br/>
                Please comment your wanted display name in the donation message.
            </span>
        </DonationSegmentComponent>
    );
};
